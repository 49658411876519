import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/markdown-page.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Angular Router`}</h1>
    <p><a parentName="p" {...{
        "href": "https://medium.com/@tomastrajan/how-to-get-route-path-parameters-in-non-routed-angular-components-32fc90d9cb52"
      }}>{`https://medium.com/@tomastrajan/how-to-get-route-path-parameters-in-non-routed-angular-components-32fc90d9cb52`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/angular/angular/issues/18469"
      }}>{`https://github.com/angular/angular/issues/18469`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.bennadel.com/blog/3375-forcing-routerlinkactive-to-update-using-an-inputs-hack-in-angular-5-0-2.htm"
      }}>{`https://www.bennadel.com/blog/3375-forcing-routerlinkactive-to-update-using-an-inputs-hack-in-angular-5-0-2.htm`}</a></p>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      